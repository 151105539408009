<template>
    <div class="settings">
        <Domains />
    </div>
</template>

<script>
import Domains from '@/components/settings/domains/index.vue';

export default {
    name: 'SettingsDomains',
    components: {
        Domains,
    },
};
</script>
